import {isIsoDate} from '../dates';

export default (props, propName, componentName) => {
  const dateValue = props[propName];

  if (
    dateValue === undefined ||
    (dateValue !== null && !isIsoDate(dateValue))
  ) {
    return new Error(
      `Invalid prop "${propName}" supplied to "${componentName}". Expected null or ISO formatted date. (found: ${dateValue})`,
    );
  }
};
